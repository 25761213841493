/* tslint:disable */
/* eslint-disable */
export * from './AccountsApi';
export * from './ActionListApi';
export * from './AlertsApi';
export * from './AreaReportsApi';
export * from './ChartsApi';
export * from './CommunicationLabelsApi';
export * from './CommunicationReportsApi';
export * from './CommunicationsApi';
export * from './DashboardsApi';
export * from './DataAnalysisApi';
export * from './DiagnosesApi';
export * from './DoctorsApi';
export * from './EmailsApi';
export * from './GroupsApi';
export * from './ImportsApi';
export * from './MedicalInstitutionLabelsApi';
export * from './MedicalInstitutionReportsApi';
export * from './MedicalInstitutionsApi';
export * from './NotificationsApi';
export * from './OwnDepartmentReportsApi';
export * from './OwnMedicalInstitutionApi';
export * from './ReferralsApi';
export * from './ReverseReferralsApi';
export * from './SalesMapApi';
export * from './SegmentsApi';
export * from './SessionsApi';
export * from './TagsApi';
export * from './TasksApi';
export * from './TenantsApi';
export * from './UploadedFileApi';
