import { Footer } from '@/src/components/features/Layout/Footer';
import { Navigation } from '@/src/components/features/Layout/Navigation';
import { SideMenu } from '@/src/components/features/Layout/SideMenu';
import { useResolveDvhUnit } from '@/src/hooks/useResolveDvhUnit';
import { useResponsive } from '@/src/hooks/useResponsive';
import { ScrollContainerRefProvider } from '@/src/hooks/useScrollContainerWidth';

import styles from './styles.module.scss';

import Error from '@mui/icons-material/Error';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';

// TODO: 下記の未対応のページをすべて対応できたら、警告表示の実装を削除する
const COMPATIBLE_PATHS: string[] = [
  // '/data_analysis',
  // '/sale_map',
  // '/tasks',
  '/medical_institutions_search',
  // '/crms/[id]/tasks',
  // '/crms/[id]/referrals',
  '/crms/[id]/communications',
  '/crms/[id]',
  // '/action_lists',
  // '/emails',
  '/communications',
  // '/emails/[id]/reports',
  // '/emails/new',
  // '/emails/[id]/edit',
  // '/alerts',
  // '/alerts/[id]',
  // '/alert_conditions',
  // '/tags',
  // '/data_uploads',
  // '/accounts',
  // '/profile/notification',
];

type Props = {
  noPadding?: true;
  children: React.ReactNode;
};

export const MainLayout: React.FC<Props> = (props) => {
  useResolveDvhUnit();

  const [isShown, setIsShown] = useState(false);
  const responsive = useResponsive();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const router = useRouter();
  const isCompatibleForSP = COMPATIBLE_PATHS.includes(router.pathname);

  const toggleSideMenu = () => {
    setIsShown((prevIsShown) => !prevIsShown);
  };

  return (
    <div className={styles.base}>
      <aside className={styles.sideMenu}>
        <SideMenu onClickCloseSideMenu={toggleSideMenu} isShown={isShown} />
      </aside>
      <header className={styles.navigation}>
        <Navigation onClickMenuButton={toggleSideMenu} />
      </header>
      <div
        id="scrollContainer"
        className={styles.scrollContainer}
        ref={scrollContainerRef}
      >
        <div className={styles.contentFooterContainer}>
          <main
            className={
              props.noPadding ? styles.contentNoPadding : styles.content
            }
          >
            <div className="alertWrapper">
              {responsive.isSp && !isCompatibleForSP && (
                <div className={styles.notCompatibleAlert}>
                  <Error fontSize="small" />
                  <p>このページはスマートフォンでの閲覧に対応していません</p>
                </div>
              )}
            </div>
            <div className="contentWrapper">
              <ScrollContainerRefProvider value={scrollContainerRef}>
                {props.children}
              </ScrollContainerRefProvider>
            </div>
          </main>
          <Footer />
        </div>
      </div>
      <div id="side-peak-root" />
    </div>
  );
};
